(function ($, Drupal, aria) {
  Drupal.behaviors.gnavUtilityItemV1 = {
    // # of seconds before the overlay appears
    defaultOpenTimeout: 3000,
    isOverlayHovered: false,
    attach: function (context) {
      var self = this;
      var $html = $('html');
      var $modules = $('.js-gnav-utility-item--v1', context);

      $modules.each(function () {
        var $module = $(this);
        var $overlayTrigger = $('.js-gnav-utility-item-trigger', $module);
        var $contentOverlay = $('.js-gnav-utility-item-overlay', $module);
        var $dialog = $('[role="dialog"]', $contentOverlay);
        var $closeDialog = $('.js-gnav-utility-item-overlay-close', $dialog);
        var closeTimeout =
          $contentOverlay.length && $contentOverlay.data('close-timeout') ? $contentOverlay.data('close-timeout') : 0;
        var displayOnload =
          $contentOverlay.length && $contentOverlay.data('display-onload')
            ? $contentOverlay.data('display-onload')
            : false;
        var hoverTriggerEvents = $overlayTrigger.data('trigger-on-hover') ? ' mouseover.overlay' : '';
        var cookieName = $dialog.attr('id') ? $dialog.attr('id') : 'gnav_utility_item_overlay';
        var hasCookie = $.cookie(cookieName);
        var closeTimer;
        var disableOverlay = Drupal.settings.common.disable_gnav_signin_overlay;
        var returnUrl = window.location.pathname;
        var signInUrl = '/account/signin.tmpl';

        $overlayTrigger
          .off('click.overlay' + hoverTriggerEvents)
          .on('click.overlay' + hoverTriggerEvents, function (e) {
            clearTimeout(closeTimer);
            if ($contentOverlay.length) {
              e.preventDefault();
              $(document).trigger('check_reset_gnav_radio_buttom');
              $html.removeClass('active-gnav').toggleClass('active-utility-overlay', true);
              $(this).attr('aria-expanded', 'true');
              self.isOverlayHovered = true;
              var isSignIn = $contentOverlay.get(0) === $('.site-header-formatter__account .js-gnav-utility-item-overlay').get(0);
              if (disableOverlay && isSignIn) {
                if (e.type === 'click') {
                  window.location = (returnUrl === '/' || returnUrl === signInUrl) ? signInUrl : signInUrl + '?return_url=' + returnUrl;
                }
              }
              else {
                $contentOverlay.trigger('launch.overlay');
              }
            }
          })
          .on('mouseleave.trigger', function () {
            if (hoverTriggerEvents) {
              _closeOverlay($closeDialog, 0.5);
              self.isOverlayHovered = false;
            }
          });

        $closeDialog.off('click.close').on('click.close', function () {
          var $this = $(this);
          var $btnTrigger = $this.closest('.js-gnav-utility-item-overlay').siblings('.js-gnav-utility-item-trigger');
          var closeButton = $this.get(0);
          var topDialog = aria.getCurrentDialog();

          if (topDialog && topDialog.dialogNode && topDialog.dialogNode.contains(closeButton)) {
            topDialog.close();
            _setCookie(cookieName, $this.data('expires'));
            if ($btnTrigger.length) {
              $btnTrigger.closest('.js-site-header-formatter-utilities-item').removeClass('gnav-utility-item--active');
              $btnTrigger.attr('aria-expanded', 'false');
            }
          }
        });

        $contentOverlay
          .off('launch.overlay')
          .on('launch.overlay', function () {
            var $btnTrigger = $(this).siblings('.js-gnav-utility-item-trigger');

            aria.closeCurrentDialog();
            if ($btnTrigger.length) {
              $btnTrigger
                .closest('.js-site-header-formatter-utilities-item')
                .siblings()
                .removeClass('gnav-utility-item--active');
              $btnTrigger.closest('.js-site-header-formatter-utilities-item').addClass('gnav-utility-item--active');
              new aria.Dialog($dialog.attr('id'), $btnTrigger.get(0));
            } else {
              new aria.Dialog($dialog.attr('id'), $module.get(0));
            }
          })
          .on('mouseenter', function () {
            clearTimeout(closeTimer);
            self.isOverlayHovered = true;
          })
          .on('mouseleave', function (e) {
            // Avoid mouseout on select input suggestion & on hover input field(Firefox).
            if (e.relatedTarget === null || e.relatedTarget.nodeName.toLowerCase() === 'input') {
              return;
            }
            // Close the overlay after a time to prevent the overlay from closing too quickly when submitting the form.
            _closeOverlay($closeDialog, 0.5);
            self.isOverlayHovered = false;
          });

        $(document).on('user.loaded', function () {
          if (!hasCookie && displayOnload) {
            // Open the Overlay on initial site load after a default timeout


            setTimeout(function () {
              $contentOverlay.trigger('launch.overlay');
              _setCookie(cookieName, $closeDialog.data('expires'));
              // Close the overlay after some timeout
              closeTimer = _closeOverlay($closeDialog, closeTimeout);
            }, self.defaultOpenTimeout);
          }
        });
      });

      function _closeOverlay($closeDialog, closeTimeout) {
        var timer = false;

        // Close the overlay after some timeout
        if (closeTimeout) {
          timer = setTimeout(function () {
            if (!self.isOverlayHovered) {
              $closeDialog.trigger('click');
              $html.toggleClass('active-utility-overlay', false);
            }
          }, closeTimeout * 1000);
        }

        return timer;
      }
      function _setCookie(cookieName, expires) {
        var attributes = {
          path: '/'
        };

        if (expires) {
          attributes.expires = expires;
        }
        $.cookie(cookieName, 1, attributes);
      }
      $(window)
        .off('closeCurrentOverlay.gnavUtilityItemV1')
        .on(
          'closeCurrentOverlay.gnavUtilityItemV1',
          _.throttle(function () {
            var topDialog = aria.getCurrentDialog();

            aria.closeCurrentDialog();
            $html.removeClass('active-utility-overlay');
            if (topDialog && topDialog.dialogNode) {
              $(topDialog.dialogNode)
                .closest('.js-site-header-formatter-utilities-item')
                .removeClass('gnav-utility-item--active');
            }
          }, 100)
        );
    }
  };
})(jQuery, Drupal, window.aria);
